import React from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Tab1.module.css';
import { useState, useEffect } from 'react';
import TitleLine from './TitleLine';
import StatGrid from './StatGrid';

const BASE_URL = 'https://api.xianggangliuhecai.com';
// const BASE_URL = 'http://localhost:8080';

function Tab1() {

  const lotteryData = [
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
    },
    {
      period: '第2024220期',
      openTime: '2024-08-07 21:32:00',
      numbers: [1, 2, 3, 4, 5, 6, 7],
      desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'],
      color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'],
    }
  ];

  const options = ["香港六合彩全站APP", "香港六合彩彩票APP", "香港六合彩真人APP", "香港六合彩真人登录器"];
  const defaultIndex = options.indexOf("香港六合彩全站APP"); // Default index for 全站APP

  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  const handleOptionSelected = (index) => {
    console.log('Selected index:', index);
    setSelectedIndex(index);
  };

  return (
    <>
      <ImageSlider />
      {/* <BorderedContainer /> */}
      <ContactSection />

      {/* <TitleLine title="澳門六合彩" subtitle="第2024220期 截止時間: 2024-08-07 21:15:00" /> */}
      <SetDataFirstTitleAndDesc />
      <LotteryUI />

      <LotteryTable lotteryData={lotteryData} />

      {/* <HeaderAppSection /> */}
      {/* <TitleLine title="预测APP图库" subtitle="独家原生APP支持全部移动端" /> */}
      <SetDataSecondTitleAndDesc />
      <div>
        <SegmentedControl
          options={options}
          selectedIndex={selectedIndex}
          onOptionSelected={handleOptionSelected}
        />
        {/* Conditionally render content based on selected index */}
        {selectedIndex === 0 && <AppFirstTab />}
        {selectedIndex === 1 && <AppSecondTab />}
        {selectedIndex === 2 && <AppThirdTab />}
        {selectedIndex === 3 && <AppFourthTab />}
      </div>

      {/* <HeaderStatisticSection /> */}
      {/* <TitleLine title="优质服务" subtitle="香港六合彩全心全意为您提供最优质的服务" /> */}
      <SetDataThirdTitleAndDesc />
      {/* <StatisticView /> */}
      <StatGrid />

      {/* <HeaderSlideImageSection /> */}
      {/* <TitleLine title="预测图库" subtitle="您想要的开云都有，带给您丰富的游戏体验" /> */}
      <SetDataFourthTitleAndDesc />
      <ImageGrid />
    </>
  );
}

function ContactSection() {

  const [contactInfo, setContactInfo] = useState({ contact: '', url: '' });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-contact-info`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.length > 0) {
          setContactInfo({ contact: data[0].contact, url: data[0].url });
        }
      } catch (err) {
        console.error('Error fetching contact info:', err.message);
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <>
      <div className={styles.outerContactContainer}>
        <div className={styles.contactContainer}>
          <div className={styles.contactItem}>
            <img src="/ic1.png" alt="Icon 1" className={styles.contactIcon} />
            <span className={styles.contactTitle}>香港六合彩全站APP</span>
            <img src="/ic5.png" alt="Forward" className={styles.contactForwardIcon} />
          </div>
          <div className={styles.contactItem}>
            <img src="/ic2.png" alt="Icon 2" className={styles.contactIcon} />
            <span className={styles.contactTitle}>香港六合彩APP</span>
            <img src="/ic5.png" alt="Forward" className={styles.contactForwardIcon} />
          </div>
        </div>

        <div className={styles.contactLine}></div>

        <div className={styles.contactDesc}>添加以下专属经理联系方式，领取免费彩金、包赔注单、精美礼品</div>

        <div className={styles.bottomSection}>
          <div className={styles.bottomItem}>
            <img src="/ic4.png" alt="Bottom Icon 1" className={styles.bottomIcon} />
            <span className={styles.bottomText}>LetsTalk客服号：{contactInfo.contact}</span> {/* Display contact from API */}
            <button className={styles.copyButton}>复制</button>
            <a href={contactInfo.url} className={styles.downloadButton} target="_blank" rel="noopener noreferrer">下载</a>
          </div>
          <div className={styles.bottomItem}>
            <img src="/ic6.png" alt="Bottom Icon 2" className={styles.bottomIcon} />
            <span className={styles.bottomText}>24小时在线客服</span>
            <a href={contactInfo.url} className={styles.contactButton} target="_blank" rel="noopener noreferrer">点击联系</a>
          </div>
        </div>
      </div>
    </>
  );
}

function ImageSlider() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   appendDots: dots => (
  //     <div className={styles.customDots}>
  //       <ul>{dots}</ul>
  //     </div>
  //   ),
  //   customPaging: i => (
  //     <div className={styles.customDot}></div>
  //   )
  // };

  // Fetch banner images from the API
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-banners`);
        const data = await response.json();
        console.log('Fetched banners:', data); // Log API response
        const imageUrls = data.map(banner => `${BASE_URL}${banner.image_url}`);
        setImages(imageUrls);
      } catch (error) {
        console.error('Error fetching banners:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (images.length === 0) {
    return <div>No banners available.</div>;
  }

  return (
    <div className={styles.sliderContainer}>
      {/* <Slider {...settings}> */}
      {images.map((url, index) => (
        <div key={index} className={styles.slide}>
          <img src={url} alt={`Slide ${index}`} className={styles.image} />
        </div>
      ))}
      {/* </Slider> */}
    </div>
  );
}

const Ball = ({ number, image }) => {
  return (
    <div className={styles.lotteryBall} style={{
      backgroundImage: `url(${image})`,
    }}>
      {number}
    </div>
  );
};

const Timer = ({ deadline }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(deadline) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className={styles.timerContainer}>
      {timeLeft.hours
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
      <span>:</span>
      {timeLeft.minutes
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
      <span>:</span>
      {timeLeft.seconds
        .toString().padStart(2, '0')
        .split('')
        .map((digit, index) => (
          <span key={index} className={styles.timeDigit}>
            {digit}
          </span>
        ))}
    </div>
  );
};

const LotteryUI = () => {
  const [title, setTitle] = useState('');
  const [balls, setBalls] = useState([]);
  const [numberSpecial, setNumberSpecial] = useState(null);
  const deadline = '2024-12-25T21:15:00'; // You can set this dynamically if needed

  // Function to assign random colors to balls
  const getRandomColor = () => {
    const colors = ['/red.png', '/blue.png', '/green.png'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Fetch lottery results from the API
  useEffect(() => {
    const fetchLotteryResults = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-lottery-results-1`);
        if (response.ok) {
          const data = await response.json();
          // Assuming the API returns numbers as number_1, number_2, etc.
          const lotteryBalls = [
            { number: data.number_1, color: getRandomColor() },
            { number: data.number_2, color: getRandomColor() },
            { number: data.number_3, color: getRandomColor() },
            { number: data.number_4, color: getRandomColor() },
            { number: data.number_5, color: getRandomColor() },
            { number: data.number_6, color: getRandomColor() },
          ];
          setTitle(data.title);
          setBalls(lotteryBalls);
          setNumberSpecial({ number: data.number_special, color: getRandomColor() });
        } else {
          console.error('Failed to fetch lottery results');
        }
      } catch (error) {
        console.error('Error fetching lottery results:', error);
      }
    };

    fetchLotteryResults();
  }, []);

  return (
    <div className={styles.lotteryResultsContainer}>
      <div className={styles.lotteryContent}>
        <div className={styles.lotteryLeft}>
          <h3>{title}</h3> {/* Fetch title from API */}
          <div className={styles.lotteryRow}>
            {balls.map((ball, index) => (
              <Ball key={index} number={ball.number} image={ball.color} />
            ))}
            <span className={styles.plusSign}>+</span>
            {numberSpecial && <Ball number={numberSpecial.number} image={numberSpecial.color} />}
          </div>
        </div>
        <div className={styles.lotteryRight}>
          <Timer deadline={deadline} />
          <div style={{ marginTop: '20px' }}>
            <button style={{ padding: '10px 20px', marginRight: '10px', backgroundColor: '#e1a61f', color: '#fff' }}>
              開獎結果
            </button>
            <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>
              直播
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SmallBall = ({ number, image, description }) => {
  return (
    <div>
      <div className={styles.lotterySmallBall} style={{
        backgroundImage: `url(${image})`,
      }}>
        {number}
      </div>
      <div style={{ fontSize: '12px', paddingTop: '6px' }}>{description}</div>
    </div>
  );
};

const LotteryTable = () => {
  const [lotteryData, setLotteryData] = useState([]);

  useEffect(() => {
    const fetchLotteryData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-history-results-1`);
        if (response.ok) {
          const data = await response.json();

          // Transform the data to match your hard-coded model
          const transformedData = data.map(item => {
            const date = new Date(item.draw_date);

            // Format date and time to dd-MM-yyyy HH:mm:ss
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

            return {
              period: item.title,
              openTime: formattedDate, // Format as dd-MM-yyyy HH:mm:ss
              numbers: [
                item.number_1,
                item.number_2,
                item.number_3,
                item.number_4,
                item.number_5,
                item.number_6,
                item.number_special
              ],
              desc: ['牛', '蛇', '牛', '鼠', '猴', '猴', '龍'], // Update descriptions as needed
              color: ['/red.png', '/blue.png', '/green.png', '/red.png', '/blue.png', '/green.png', '/red.png'], // Update colors as needed
            };
          });

          setLotteryData(transformedData);
        } else {
          console.error('Failed to fetch lottery data.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchLotteryData();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tableHeaders = isMobile
    ? ['期號', '中獎號碼']
    : ['期號', '開獎時間', '中獎號碼', '開獎回放'];

  return (
    <table className={styles.lotteryTable}>
      <thead>
        <tr>
          {tableHeaders.map((header, index) => (
            <th key={header} className={`column-${index + 1}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {lotteryData.map((row, index) => (
          <tr key={index}>
            <td className={styles.lotteryTableColumn1}>{row.period}</td>
            <td className={styles.lotteryTableColumn2}>{row.openTime}</td>
            <td className={styles.lotteryTableColumn3}>
              <div className={styles.lotteryRow}>
                {row.numbers.slice(0, -1).map((ball, index) => (
                  <SmallBall key={index} number={ball} image={row.color[index]} description={row.desc[index]} />
                ))}
                <span className={styles.smallPlusSign}>+</span>
                <SmallBall key={row.numbers.length - 1} number={row.numbers[row.numbers.length - 1]} image={row.color[row.numbers.length - 1]} description={row.desc[row.numbers.length - 1]} />
              </div>
            </td>
            <td className={styles.lotteryTableColumn4}>
              <button style={{ padding: '10px 20px', backgroundColor: 'red', color: '#fff' }}>回放</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function ImageCard({ imageSrc, description }) {
  return (
    <div className={styles.imageCard}>
      <img src={imageSrc} alt="" className={styles.imageItem} />
      <p className={styles.description}>{description}</p>
    </div>
  );
}

function ImageGrid() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${BASE_URL}/news-articles`);
        const data = await response.json();
        console.log('Fetched data:', data); // Verify data fetched
        setImages(data); // Update state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    }

    fetchData();
  }, []);

  return (
    <div className={styles.imageGrid}>
      {images.map((image, index) => (
        <ImageCard key={index} imageSrc={`${BASE_URL}${image.image_url}`}
          description={image.title} />
      ))}
    </div>
  );
}

const SegmentedControl = ({ options, selectedIndex, onOptionSelected }) => {
  const handleOptionClick = (index) => {
    onOptionSelected(index);
  };

  return (
    <div className={styles.segmentedControl}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`${styles.segment} ${selectedIndex === index ? styles.selected : ''}`}
          onClick={() => handleOptionClick(index)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

const AppContainer = ({ imageSrc, title, description }) => {
  return (
    <div className={styles.appContainer}>
      <div className={styles.appImageContainer}>
        <img src={imageSrc} alt="" width="100%" height="100%" />
      </div>
      <div className={styles.appContentContainer}>
        <h3 className={styles.appTitle}>{title}</h3>
        <p className={styles.appDesc}>{description}</p>
        {/* <img src='/qr.png' alt="" width="150px" height="150px" />
        <p style={{ fontSize: "16px", lineHeight: "1.5", fontWeight: "500", color: "#7881a4" }}>扫码下载</p> */}
      </div>
    </div>
  );
};

function AppFirstTab() {
  const [introductionData, setIntroductionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API using fetch
    fetch(`${BASE_URL}/get-introduction-app-1`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setIntroductionData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AppContainer
      imageSrc={`${BASE_URL}${introductionData.image_url}`} // Using BASE_URL here
      title={introductionData.title}
      description={introductionData.content}
    />
  );
}

function AppSecondTab() {
  const [introductionData, setIntroductionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API using fetch
    fetch(`${BASE_URL}/get-introduction-app-2`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setIntroductionData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AppContainer
      imageSrc={`${BASE_URL}${introductionData.image_url}`} // Using BASE_URL here
      title={introductionData.title}
      description={introductionData.content}
    />
  );
}

function AppThirdTab() {
  const [introductionData, setIntroductionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API using fetch
    fetch(`${BASE_URL}/get-introduction-app-3`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setIntroductionData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AppContainer
      imageSrc={`${BASE_URL}${introductionData.image_url}`} // Using BASE_URL here
      title={introductionData.title}
      description={introductionData.content}
    />
  );
}

function AppFourthTab() {
  const [introductionData, setIntroductionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API using fetch
    fetch(`${BASE_URL}/get-introduction-app-4`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setIntroductionData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AppContainer
      imageSrc={`${BASE_URL}${introductionData.image_url}`} // Using BASE_URL here
      title={introductionData.title}
      description={introductionData.content}
    />
  );
}

function SetDataFirstTitleAndDesc() {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-title-subtitle-1`);
        if (response.ok) {
          const data = await response.json();
          setTitle(data.title);
          setSubTitle(data.subtitle);
        } else {
          console.error('Failed to fetch title and subtitle.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TitleLine title={title} subtitle={subTitle} />
  );
}

function SetDataSecondTitleAndDesc() {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-title-subtitle-2`);
        if (response.ok) {
          const data = await response.json();
          setTitle(data.title);
          setSubTitle(data.subtitle);
        } else {
          console.error('Failed to fetch title and subtitle.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TitleLine title={title} subtitle={subTitle} />
  );
}

function SetDataThirdTitleAndDesc() {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-title-subtitle-3`);
        if (response.ok) {
          const data = await response.json();
          setTitle(data.title);
          setSubTitle(data.subtitle);
        } else {
          console.error('Failed to fetch title and subtitle.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TitleLine title={title} subtitle={subTitle} />
  );
}

function SetDataFourthTitleAndDesc() {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-title-subtitle-4`);
        if (response.ok) {
          const data = await response.json();
          setTitle(data.title);
          setSubTitle(data.subtitle);
        } else {
          console.error('Failed to fetch title and subtitle.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TitleLine title={title} subtitle={subTitle} />
  );
}

export default Tab1;